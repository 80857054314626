import React, { Component } from "react";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import TopButton from "../../components/topButton/TopButton";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import Button from "../../components/button/Button";
import BlogsImg from "./BlogsImg";
import AddressImg from "./AddressImg";
import { Fade } from "react-reveal";
import "./ContactComponent.css";
import { greeting, contactPageData } from "../../portfolio.js";

const ContactData = contactPageData.contactSection;
const blogSection = contactPageData.blogSection;
const addressSection = contactPageData.addressSection;
const phoneSection = contactPageData.phoneSection;

class Contact extends Component {
  render() {
    const theme = this.props.theme;
    return (
      <div className="contact-main">
        <Header theme={theme} />
        <div className="basic-contact">
          <Fade bottom duration={1000} distance="40px">
            <div className="contact-heading-div">
              <div className="contact-heading-img-div">
                <img
                  src={require(`../../assests/images/About.png`)}
                  alt=""
                />
              </div>
              <div className="contact-heading-text-div">
                <h1
                  className="contact-heading-text"
                  style={{ color: theme.text }}
                >
                  關於我們
                </h1>
                <p
                  className="contact-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  "遊戲人生"是一家致力於利用最新科技，特別是人工智能（AI）技術，推動遊戲教育和科技發展的前沿公司。我們深信，遊戲不僅是娛樂的工具，更是教育和科技進步的推動器。
                </p>
                <p
                  className="contact-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  我們的核心理念是通過遊戲化的方式，讓學習變得更加有趣和高效。為了實現這一目標，我們利用先進的AI技術，將開發出一系列寓教於樂的遊戲。這些遊戲能夠根據每個用戶的學習進度和能力進行個性化調整，提供最適合他們的學習體驗。
                </p>
                <p
                  className="contact-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  AI技術不僅用於個性化學習，還被用於遊戲設計和開發過程中。我們的AI工具可以自動生成新的遊戲關卡和挑戰，從而為用戶提供無窮的新體驗。此外，我們還利用AI進行數據分析，以了解用戶的行為模式，從而持續改進我們的產品。
                </p>
                <p
                  className="contact-header-detail-text subTitle"
                  style={{ color: theme.secondaryText }}
                >
                  遊戲是未來教育和科技發展的關鍵。通過將AI和遊戲結合，我們希望能夠推動教育的現代化，同時也推動AI技術的發展。我們堅信，通過我們的努力，"遊戲人生"將能夠為全世界的用戶提供一種全新的，寓教於樂的學習方式。
                </p>
                <SocialMedia theme={theme} />
                {/* <div className="resume-btn-div">
                  <Button
                    text="See My Resume"
                    newTab={true}
                    href={greeting.resumeLink}
                    theme={theme}
                  />
                </div> */}
              </div>
            </div>
          </Fade>
          
        </div>
        <Footer theme={this.props.theme} onToggle={this.props.onToggle} />
        <TopButton theme={this.props.theme} />
      </div>
    );
  }
}

export default Contact;
