/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Gaming Life",
  description:
    "我們深信，遊戲不僅是娛樂的工具，更是教育和科技進步的推動器。",
  og: {
    title: "Gaming Life",
    type: "website",
    url: "http://gaminglifehk.com/",
  },
};

//Home Page
const greeting = {
  title: "Gaming Life",
  logo_name: "GamingLife",
  nickname: "遊戲人生",
  subTitle:
    "我們深信，遊戲不僅是娛樂的工具，更是教育和科技進步的推動器。",
  resumeLink:
    "https://drive.google.com/file/d/1FL_wydnPF2SWCdw2goYAgZ1KKBx8TpcD/view?usp=sharing",
  portfolio_repository: "https://github.com/ashutosh1919/masterPortfolio",
  githubProfile: "https://github.com/ashutosh1919",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/ashutosh1919",
  // linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  // gmail: "ashutoshhathidara98@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/layman_brother/"

  {
    name: "Github",
    link: "https://github.com/ashutosh1919",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/ashutosh1919/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "YouTube",
    link: "https://youtube.com/c/DevSense19",
    fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
    backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  },
  {
    name: "Gmail",
    link: "mailto:ashutoshhathidara98@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "X-Twitter",
    link: "https://twitter.com/ashutosh_1919",
    fontAwesomeIcon: "fa-x-twitter", // Reference https://fontawesome.com/icons/x-twitter?f=brands&s=solid
    backgroundColor: "#000000", // Reference https://simpleicons.org/?q=x
  },
  {
    name: "Facebook",
    link: "https://www.facebook.com/laymanbrother.19/",
    fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/layman_brother/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
];

const skills = {
  data: [
    {
      title: "遊戲開發",
      fileName: "Development",
      skills: [
        "⚡ 我們不一定是最好的遊戲開發者，但我們一定是喜愛玩遊戲的遊戲開發者。",
        "⚡ 以遊戲來推動教育和科技發展。",
        "⚡ 結合最尖端的科技配合大家的創意，開發出大家喜愛的遊戲。",
      ],
      softwareSkills: [
      //   {
      //     skillName: "Tensorflow",
      //     fontAwesomeClassname: "logos-tensorflow",
      //     style: {
      //       backgroundColor: "transparent",
      //     },
      //   },
      //   {
      //     skillName: "Keras",
      //     fontAwesomeClassname: "simple-icons:keras",
      //     style: {
      //       backgroundColor: "white",
      //       color: "#D00000",
      //     },
      //   },
      //   {
      //     skillName: "PyTorch",
      //     fontAwesomeClassname: "logos-pytorch",
      //     style: {
      //       backgroundColor: "transparent",
      //     },
      //   },
      //   {
      //     skillName: "Python",
      //     fontAwesomeClassname: "ion-logo-python",
      //     style: {
      //       backgroundColor: "transparent",
      //       color: "#3776AB",
      //     },
      //   },
      //   {
      //     skillName: "Deeplearning",
      //     imageSrc: "deeplearning_ai_logo.png",
      //   },
      ],
    },
    {
      title: "人工智能(AI)",
      fileName: "AI",
      skills: [
        "⚡ 人工智能(AI)的出現改變生活",
        "⚡ 以人工智能(AI)輔助，提高學習興趣，專項強化學習及提供詳細的學習建議。",
        "⚡ 推動人工智能(AI)普及化，降低使用人工智能(AI)的門檻。",
      ],
      softwareSkills: [
        // {
        //   skillName: "HTML5",
        //   fontAwesomeClassname: "simple-icons:html5",
        //   style: {
        //     color: "#E34F26",
        //   },
        // },
        // {
        //   skillName: "CSS3",
        //   fontAwesomeClassname: "fa-css3",
        //   style: {
        //     color: "#1572B6",
        //   },
        // },
        // {
        //   skillName: "Sass",
        //   fontAwesomeClassname: "simple-icons:sass",
        //   style: {
        //     color: "#CC6699",
        //   },
        // },
        // {
        //   skillName: "JavaScript",
        //   fontAwesomeClassname: "simple-icons:javascript",
        //   style: {
        //     backgroundColor: "#000000",
        //     color: "#F7DF1E",
        //   },
        // },
        // {
        //   skillName: "ReactJS",
        //   fontAwesomeClassname: "simple-icons:react",
        //   style: {
        //     color: "#61DAFB",
        //   },
        // },
        // {
        //   skillName: "NodeJS",
        //   fontAwesomeClassname: "simple-icons:node-dot-js",
        //   style: {
        //     color: "#339933",
        //   },
        // },
        // {
        //   skillName: "NPM",
        //   fontAwesomeClassname: "simple-icons:npm",
        //   style: {
        //     color: "#CB3837",
        //   },
        // },
        // {
        //   skillName: "Yarn",
        //   fontAwesomeClassname: "simple-icons:yarn",
        //   style: {
        //     color: "#2C8EBB",
        //   },
        // },
        // {
        //   skillName: "Gatsby",
        //   fontAwesomeClassname: "simple-icons:gatsby",
        //   style: {
        //     color: "#663399",
        //   },
        // },
        // {
        //   skillName: "Flutter",
        //   fontAwesomeClassname: "simple-icons:flutter",
        //   style: {
        //     color: "#02569B",
        //   },
        // },
      ],
    },
    {
      title: "商業網站/系統開發",
      fileName: "BD",
      skills: [
        "⚡ 提供網站開發服務。",
        "⚡ 網店開發及管理服務。",
        "⚡ 專業的廣告顧問服務(包括戶外廣告、網上廣告、社交媒體廣告等)。",
      ],
      //https://www.roblox.com/games/9860164498/Hi-Seek-MetaFair
      softwareSkills: [
        // {
        //   skillName: "GCP",
        //   fontAwesomeClassname: "simple-icons:googlecloud",
        //   style: {
        //     color: "#4285F4",
        //   },
        // },
        // {
        //   skillName: "AWS",
        //   fontAwesomeClassname: "simple-icons:amazonaws",
        //   style: {
        //     color: "#FF9900",
        //   },
        // },
        // {
        //   skillName: "Azure",
        //   fontAwesomeClassname: "simple-icons:microsoftazure",
        //   style: {
        //     color: "#0089D6",
        //   },
        // },
        // {
        //   skillName: "Firebase",
        //   fontAwesomeClassname: "simple-icons:firebase",
        //   style: {
        //     color: "#FFCA28",
        //   },
        // },
        // {
        //   skillName: "PostgreSQL",
        //   fontAwesomeClassname: "simple-icons:postgresql",
        //   style: {
        //     color: "#336791",
        //   },
        // },
        // {
        //   skillName: "MongoDB",
        //   fontAwesomeClassname: "simple-icons:mongodb",
        //   style: {
        //     color: "#47A248",
        //   },
        // },
        // {
        //   skillName: "Docker",
        //   fontAwesomeClassname: "simple-icons:docker",
        //   style: {
        //     color: "#1488C6",
        //   },
        // },
        // {
        //   skillName: "Kubernetes",
        //   fontAwesomeClassname: "simple-icons:kubernetes",
        //   style: {
        //     color: "#326CE5",
        //   },
        // },
      ],
    },
    {
      title: "商業雲端管理及支援",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ 提供專業的雲端管理及支援服務",
        "⚡ 協助企業管理雲端平台",
      ],
      softwareSkills: [
        {
          skillName: "GCP",
          fontAwesomeClassname: "simple-icons:googlecloud",
          style: {
            color: "#4285F4",
          },
        },
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Azure",
          fontAwesomeClassname: "simple-icons:microsoftazure",
          style: {
            color: "#0089D6",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },
        // {
        //   skillName: "Adobe XD",
        //   fontAwesomeClassname: "simple-icons:adobexd",
        //   style: {
        //     color: "#FF2BC2",
        //   },
        // },
        // {
        //   skillName: "Figma",
        //   fontAwesomeClassname: "simple-icons:figma",
        //   style: {
        //     color: "#F24E1E",
        //   },
        // },
        // {
        //   skillName: "Adobe Illustrator",
        //   fontAwesomeClassname: "simple-icons:adobeillustrator",
        //   style: {
        //     color: "#FF7C00",
        //   },
        // },
        // {
        //   skillName: "Inkscape",
        //   fontAwesomeClassname: "simple-icons:inkscape",
        //   style: {
        //     color: "#000000",
        //   },
        // },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    // {
    //   siteName: "LeetCode",
    //   iconifyClassname: "simple-icons:leetcode",
    //   style: {
    //     color: "#F79F1B",
    //   },
    //   profileLink: "https://leetcode.com/layman_brother/",
    // },
    // {
    //   siteName: "HackerRank",
    //   iconifyClassname: "simple-icons:hackerrank",
    //   style: {
    //     color: "#2EC866",
    //   },
    //   profileLink: "https://www.hackerrank.com/layman_brother",
    // },
    // {
    //   siteName: "Codechef",
    //   iconifyClassname: "simple-icons:codechef",
    //   style: {
    //     color: "#5B4638",
    //   },
    //   profileLink: "https://www.codechef.com/users/ashutosh_1919",
    // },
    // {
    //   siteName: "Codeforces",
    //   iconifyClassname: "simple-icons:codeforces",
    //   style: {
    //     color: "#1F8ACB",
    //   },
    //   profileLink: "http://codeforces.com/profile/layman_brother",
    // },
    // {
    //   siteName: "Hackerearth",
    //   iconifyClassname: "simple-icons:hackerearth",
    //   style: {
    //     color: "#323754",
    //   },
    //   profileLink: "https://www.hackerearth.com/@ashutosh391",
    // },
    // {
    //   siteName: "Kaggle",
    //   iconifyClassname: "simple-icons:kaggle",
    //   style: {
    //     color: "#20BEFF",
    //   },
    //   profileLink: "https://www.kaggle.com/laymanbrother",
    // },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Roblox 元宇宙市集",
      subtitle: "Hi and Seek MetaFair",
      logo_path: "roblox.png",
      alt_name: "Roblox",
      duration: "2023",
      descriptions: [
        "⚡ 配合實體商戶，通過元宇宙互動加強宣傳效果，通過Roblox知名度減低推廣費用。",
        "⚡ 無縫的虛實整合: Roblox 的元宇宙線上線下市集能夠將虛擬世界與現實世界無縫連接，為玩家帶來全新的體驗。例如，玩家可以在虛擬世界中購買商品，然後在現實世界中收到實體商品，或者在現實世界中參加活動，然後在虛擬世界中獲得獎勵。這種虛實整合為玩家提供了更豐富、更具吸引力的體驗。",
        "⚡ 擴大玩家參與度: 通過結合線上和線下的活動，Roblox 的元宇宙市集可以吸引更多的玩家參與。玩家不僅可以在家中透過電腦或移動裝置參與虛擬世界的活動，也可以在現實世界中參與各種活動，如線下聚會、比賽等。這種模式可以擴大玩家的參與度，讓他們更深入地投入到遊戲和社區中。",
        "⚡ 強化社區連結: 線上線下市集的結合可以強化玩家間的社區連結。玩家可以在線上共享遊戲體驗，也可以在線下見面交流，這種人際互動可以增強玩家的社區歸屬感。此外，這種模式還可以讓玩家在現實世界中體驗到虛擬世界的樂趣，進一步增強他們對遊戲和社區的投入。",
      ],
      website_link: "https://www.roblox.com/games/9860164498/Hi-Seek-MetaFair",
    },
    {
      title: "市場行銷的HTML5互動/遊戲頁",
      subtitle: "HTML5 / Game Engine(unity,threejs) For Marketing",
      logo_path: "marketing.png",
      alt_name: "HTML5",
      duration: "2019 - 2023",
      descriptions: [
        "⚡ 實際案例: MTR Mobile App, KMB 節日campaign<農曆新年，聖誕及世界盃等>等時效性互動頁面開發。 (因互動性頁面一般為時效性，所以未能提供參考，如有需要請聯絡我們。)",
        "⚡ 提升用戶參與度: HTML5互動/遊戲頁通過提供有趣的遊戲體驗，可以吸引用戶花費更多時間在你的網站或線上平台上。這不僅可以提高用戶的參與度，還可以增加他們對你的品牌或產品的認識。",
        "⚡ 增強品牌認知度: 透過HTML5互動/遊戲頁，你可以將你的品牌或產品巧妙地融入遊戲中，例如，可以設計與你的產品相關的遊戲角色或任務。這種方式不僅可以讓用戶在玩遊戲的過程中無意識地接觸到你的品牌，還可以讓他們以更輕鬆的方式理解你的產品或服務。",
        "⚡ 數據收集和分析: HTML5互動/遊戲頁還可以作為一種數據收集工具。你可以通過追蹤用戶在遊戲中的行為，例如他們在遊戲中花費的時間、完成的任務等，來收集有關用戶的數據。這些數據可以用來分析用戶的行為和喜好，並用於改進你的產品或服務，或者優化你的行銷策略。",
      ],
      website_link: "",
    },
    //https://pottinger22.com/zh-hant/
    //https://vets-store.com/
    {
      title: "Wix/Shopify網頁管理/二次功能開發",
      subtitle: "E-Commerce Website",
      logo_path: "wix.png",
      alt_name: "HTML5",
      duration: "2020 - 2023",
      descriptions: [
        "⚡ 客製化網頁開發與管理服務: 我們的專業團隊將為您的 Wix 或 Shopify 網站提供全方位的開發與管理服務。我們專注於打造高度客製化的網站，以符合您的商業需求並提升用戶體驗。我們的服務包含網站設計、功能開發、內容管理，以及後續的網站維護和更新。",
        "⚡ 二次功能開發: 我們認識到每個業務都有其獨特性，可能需要超越 Wix 或 Shopify 原生功能的特殊需求。因此，我們提供二次功能開發服務，包括購物車客製化、特殊支付方式整合、產品管理系統開發等，讓您的網站更符合您的業務需求。",
        "⚡ 全面的技術支援: 我們的服務不僅止於開發和管理，我們還提供全面的技術支援，包括問題排除、技術諮詢和解決方案提供。我們的目標是讓您能夠專注於您的業務，而我們會處理所有的技術問題，讓您的網站順暢運作。",
      ],
      website_link: "https://pottinger22.com/zh-hant/",
      website_link2: "https://vets-store.com/",
    },
    {
      title: "個人或小型網頁開發",
      subtitle: "Website Development",
      logo_path: "wordpress.png",
      alt_name: "HTML5",
      duration: "2023",
      descriptions: [
        "⚡ 個性化網頁設計: 我們的專業團隊會根據您的需求和品牌形象，設計出獨特且具有吸引力的網頁。我們理解個人和小型企業需要展現其獨特性，因此我們的設計都將充分考慮到您的個性和商業目標，以確保網頁設計與您的品牌定位相符。",
        "⚡ 靈活的功能開發: 我們提供各種網頁功能的開發，包括博客、線上商店、預約系統、聯絡表單等。我們的開發服務旨在滿足您的特定需求，無論您希望創建一個簡單的個人部落格，還是一個功能豐富的小型企業網站，我們都能提供專業的解決方案。",
        "⚡ 持續的網站維護與優化: 我們的服務不止於網頁的開發和設計，我們還提供持續的網頁維護和優化服務。這包括定期更新內容、優化網站性能、確保網站的安全性等。我們的目標是讓您的網站始終保持最佳狀態，以提供最佳的用戶體驗。",
      ],
      website_link: "https://wpmshk.com/",
    },
    //https://www.infinitus.com.hk/zh/
    //https://www.asiaray.com
    {
      title: "企業系統開發及管理",
      subtitle: "Corporate Website Development",
      logo_path: "erp.png",
      alt_name: "HTML5",
      duration: "2023",
      descriptions: [
        "⚡ 客製化企業系統開發: 我們的專業開發團隊致力於為您的企業創建高度客製化的系統。我們理解每個企業都有其獨特的運營流程和需求，因此我們會與您緊密合作，了解您的業務需求，並開發出完全符合您需求的企業系統，無論是資源管理系統（ERP）、客戶關係管理系統（CRM）、人力資源管理系統（HRM）還是其他專業的業務流程管理系統。",
        "⚡ 系統整合與優化: 在現代企業中，擁有多個不同的系統是常見的情況。我們的服務不僅包括開發新的企業系統，還包括整合和優化現有的系統。我們的目標是確保所有的系統能夠無縫地協同工作，提高業務效率並降低運營成本。",
        "⚡ 持續的系統維護與管理: 我們提供全面的企業系統維護和管理服務，包括定期的系統更新、問題排查、性能優化等。我們的專業團隊將確保您的企業系統始終運行在最佳狀態，並隨著您的業務需求進行適時的調整和更新。",
      ],
      website_link: "https://www.infinitus.com.hk/zh/",
      website_link2: "https://www.asiaray.com",
    },
    {
      title: "AR/VR開發",
      subtitle: "AR/VR/MR",
      logo_path: "arvr.png",
      alt_name: "AR/VR/MR",
      duration: "2023",
      descriptions: [
        "⚡ 沉浸式體驗開發: 我們的專業團隊擁有在AR（擴增實境）和VR（虛擬實境）開發中的豐富經驗。我們利用最先進的技術創建沉浸式的、互動式的3D環境，讓用戶能夠在虛擬世界中體驗到真實世界的感覺。無論是為產品展示、教育訓練還是娛樂遊戲開發AR/VR應用，我們都能提供高質量的解決方案。",
        "⚡ 跨平台兼容: 我們的AR/VR開發服務支持各種主流的硬體設備，包括Oculus、HTC Vive、Microsoft HoloLens等。我們的目標是創造出可以在多種設備上流暢運行的應用，以達到最廣泛的用戶覆蓋。",
        "⚡ 持續的技術支持與更新: 隨著AR/VR技術的不斷進步，我們也會定期更新我們的開發技術和工具，以確保我們提供的產品始終處於行業的前沿。此外，我們也提供持續的技術支持，包括問題排查、功能優化和新功能的添加，以滿足您的長期需求。",
      ],
      website_link: "",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Machine Learning",
      subtitle: "- Andrew Ng",
      logo_path: "stanford_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/22MTSSC5WDTM",
      alt_name: "Stanford University",
      color_code: "#8C151599",
    },
    {
      title: "Deep Learning",
      subtitle: "- Andrew Ng",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/specialization/H8CPSFXAJD2G",
      alt_name: "deeplearning.ai",
      color_code: "#00000099",
    },
    {
      title: "ML on GCP",
      subtitle: "- GCP Training",
      logo_path: "google_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/specialization/EB4VJARK8647",
      alt_name: "Google",
      color_code: "#0C9D5899",
    },
    {
      title: "Data Science",
      subtitle: "- Alex Aklson",
      logo_path: "ibm_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/specialization/PLEAPCSJBZT5",
      alt_name: "IBM",
      color_code: "#1F70C199",
    },
    {
      title: "Big Data",
      subtitle: "- Kim Akers",
      logo_path: "microsoft_logo.png",
      certificate_link:
        "https://drive.google.com/file/d/164zKCFOsI4vGqokc-Qj-e_D00kLDHIrG/view",
      alt_name: "Microsoft",
      color_code: "#D83B0199",
    },
    {
      title: "Advanced Data Science",
      subtitle: "- Romeo Kienzler",
      logo_path: "ibm_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/BH2T9BRU87BH",
      alt_name: "IBM",
      color_code: "#1F70C199",
    },
    {
      title: "Advanced ML on GCP",
      subtitle: "- GCP Training",
      logo_path: "google_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/5JZZM7TNQ2AV",
      alt_name: "Google",
      color_code: "#0C9D5899",
    },
    {
      title: "DL on Tensorflow",
      subtitle: "- Laurence Moroney",
      logo_path: "deeplearning_ai_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/verify/6T4DCUGNK8J8",
      alt_name: "deeplearning.ai",
      color_code: "#00000099",
    },
    {
      title: "Fullstack Development",
      subtitle: "- Jogesh Muppala",
      logo_path: "coursera_logo.png",
      certificate_link:
        "https://www.coursera.org/account/accomplishments/certificate/NRANJA66Y2YA",
      alt_name: "Coursera",
      color_code: "#2A73CC",
    },
    {
      title: "Kuberenetes on GCP",
      subtitle: "- Qwiklabs",
      logo_path: "gcp_logo.png",
      certificate_link:
        "https://google.qwiklabs.com/public_profiles/e4d5a92b-faf6-4679-a70b-a9047c0cd750",
      alt_name: "GCP",
      color_code: "#4285F499",
    },
    {
      title: "Cryptography",
      subtitle: "- Saurabh Mukhopadhyay",
      logo_path: "nptel_logo.png",
      certificate_link:
        "https://drive.google.com/open?id=1z5ExD_QJVdU0slLkp8CBqSF3-C3g-ro_",
      alt_name: "NPTEL",
      color_code: "#FFBB0099",
    },
    {
      title: "Cloud Architecture",
      subtitle: "- Qwiklabs",
      logo_path: "gcp_logo.png",
      certificate_link:
        "https://google.qwiklabs.com/public_profiles/5fab4b2d-be6f-408c-8dcb-6d3b58ecb4a2",
      alt_name: "GCP",
      color_code: "#4285F499",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I have worked with many evolving startups as ML and DL Developer, Designer and Software Architect. I have also worked with some well established companies mostly as AI Developer. I love organising events and that is why I am also involved with many opensource communities as a representative.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Associate AI Engineer",
          company: "Legato Health Technology",
          company_url: "https://legatohealthtech.com/",
          logo_path: "legato_logo.png",
          duration: "June 2020 - Aug 2021",
          location: "Hyderabad, Telangana",
          description:
            "I am working on automating healthcare products. The projects involve automation for process improvements and for significantly enhancing the profits. I am currently working on Cancer Survival and Reoccurence Prediction. Our goal is to make AI system which scales and removes doctor dependency as much as possible.",
          color: "#0879bf",
        },
        {
          title: "Android and ML Developer",
          company: "Muffito Incorporation",
          company_url: "https://www.linkedin.com/company/muffito-inc/about/",
          logo_path: "muffito_logo.png",
          duration: "May 2018 - Oct 2018",
          location: "Pune, Maharashtra",
          description:
            "I have created complete Android Application for locating Pub, Bar and beverage shops around you. I have also worked on implementation of algorithms for Face Detection, Text extraction from Image. I was involved in a team for creating complete software architecure of mobile and web application as well as admin panel for company.",
          color: "#9b1578",
        },
        {
          title: "Android Developer",
          company: "FreeCopy Pvt. Ltd.",
          company_url: "https://www.linkedin.com/company/freecopy/about/",
          logo_path: "freecopy_logo.png",
          duration: "Nov 2017 - Dec 2017",
          location: "Ahmedabad, Gujarat",
          description:
            "FreeCopy is the Start up from Indian Institute of Management, Ahmedabad. I have changed the integration of the whole app from Google to Firebase. I learnt the efﬁcient ways of Data communications like Retroﬁt, Eventbus etc. I experienced the real time start up. I learnt the Design thinking of UI on perspective of People.",
          color: "#fc1f20",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Machine Learning Intern",
          company: "TikTok Inc.",
          company_url: "https://www.tiktok.com/en/",
          logo_path: "tiktok_logo.png",
          duration: "May 2022 - Aug 2022",
          location: "San Francisco, USA",
          description:
            "Building new features on the backend recommendation system, specifically ranking algorithms for Ads that touch hundreds of millions of people around the world. Improving online and offline content ranking algorithms by performing hard sample data replays for training steps.",
          color: "#000000",
        },
        {
          title: "Data Science Research Intern",
          company: "Delhivery Pvt. Ltd.",
          company_url: "https://www.delhivery.com/",
          logo_path: "delhivery_logo.png",
          duration: "May 2019 - Sept 2019",
          location: "Gurgaon, Haryana",
          description:
            "I have worked on project of predicting freight rates based on previous data. There were two objectives: (1) To build a forecasting engine to predict daily freight rates. (2) To embed feature in the model which can explain the seasonal major changes in freight rate based on regions and locations. I have closely worked with deep learning models in combination with statistical methods to create solution for this. At the end of internship, I had created model deployed on AWS EC2 with the use of Kafka stream jobs, ElasticSearch and PostgreSQL.",
          color: "#ee3c26",
        },
        {
          title: "Data Science Intern",
          company: "Intel Indexer LLC",
          company_url:
            "https://opencorporates.com/companies/us_dc/EXTUID_4170286",
          logo_path: "intel_logo.jpg",
          duration: "Nov 2018 - Dec 2018",
          location: "Work From Home",
          description:
            "This is financial Solution Company. I have made Supervised Learning model for the company which can perform time series analysis on Stock price data for 32 companies. I have built LSTM Neural Networks Model and trained the data of 32 companies for last 2 years. This model is also used for forecasting.",
          color: "#0071C5",
        },
      ],
    },
    {
      title: "Volunteerships",
      experiences: [
        {
          title: "Google Explore ML Facilitator",
          company: "Google",
          company_url: "https://about.google/",
          logo_path: "google_logo.png",
          duration: "June 2019 - April 2020",
          location: "Hyderabad, Telangana",
          description:
            "Explore Machine Learning (ML) is a Google-sponsored program for university students to get started with Machine Learning. The curriculum offers 3 tracks of ML Content (Beginner, Intermediate, Advanced) and relies on university student facilitators to train other students on campus and to build opensource projects under this program.",
          color: "#4285F4",
        },
        {
          title: "Microsoft Student Partner",
          company: "Microsoft",
          company_url: "https://www.microsoft.com/",
          logo_path: "microsoft_logo.png",
          duration: "Aug 2019 - May 2020",
          location: "Hyderabad, Telangana",
          description:
            "Microsoft Student Partner is a program for university students to lead the awareness and use of Cloud especially Azure tools in the development of their projects and startups. Under this program, I have organised hands on workshops and seminars to teach Cloud Computing concepts to students.",
          color: "#D83B01",
        },
        {
          title: "Mozilla Campus Captain",
          company: "Mozilla",
          company_url: "https://www.mozilla.org/",
          logo_path: "mozilla_logo.png",
          duration: "Oct 2019 - May 2020",
          location: "Kurnool, Andhra Pradesh",
          description:
            "My responsibility for this program was to create opensource environment in college and in the city. We have organised multiple hackathons on the problems collected by ordinary people from Kurnool city. We have build opensource community of our own college. The community is available at dsc_iiitdmk on github.",
          color: "#000000",
        },
        {
          title: "Developer Students Club Member",
          company: "DSC IIITDM Kurnool",
          company_url:
            "https://www.linkedin.com/company/developer-students-club-iiitdm-kurnool",
          logo_path: "dsc_logo.png",
          duration: "Jan 2018 - May 2020",
          location: "Kurnool, Andhra Pradesh",
          description:
            "We have well established developer club in college which is directly associated with Google Developers. We have developed many interdisciplinary projects under the membership of this club. We have organised workshops and activities on Android Application Development, Flutter and React JS.",
          color: "#0C9D58",
        },
        {
          title: "Developer Program Member",
          company: "Github",
          company_url: "https://github.com/",
          logo_path: "github_logo.png",
          duration: "July 2019 - PRESENT",
          location: "Work From Home",
          description:
            "I am actively contributing to many opensource projects. I have contributed to projects of organisations like Tensorflow, Uber, Facebook, Google, Scikit-learn, Kiwix, Sympy, Python, NVLabs, Fossasia, Netrack, Keras etc. These contributions include bug fixes, feature requests and formulating proper documentation for project.",
          color: "#181717",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create Data Science projects and deploy them to web applications using cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publications",
  description:
    "I have worked on and published a few research papers and publications of my own.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzQ=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzi=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNze=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzt=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
    {
      id: "MDEwOlJlcG9zaXRvcnkyNDU0NjcyNzb=",
      name: "Artificial Intelligence Paper",
      createdAt: "2020-03-06T16:26:54Z",
      description: "Paper Written on Artificial Intelligence published in xyz ",
      url:
        "https://www.andrewng.org/publications/building-high-level-features-using-large-scale-unsupervised-learning/",
    },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "animated_ashutosh.png",
    description:
      "I am available on almost every social media. You can message me, I will reply within 24 hours. I can help you with ML, AI, React, Android, Cloud and Opensource Development.",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "For individual fundamental empowerment, I like to write powerful lessons that create impact on each of the reader individually to change the core of their character.",
    link: "https://blogs.ashutoshhathidara.com/",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle:
      "Ambavadi vas, Kanodar, T.A.-Palanpur, Dist.-Banaskantha, Gujarat - 385520",
    locality: "Kanodar",
    country: "IN",
    region: "Gujarat",
    postalCode: "385520",
    streetAddress: "Ambavadi vas",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://goo.gl/maps/MpMqtRZytFchMkZ76",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
